import _ImageViewer2 from "./ImageViewer";
import _FullScreen2 from "./FullScreen";
var exports = {};
Object.defineProperty(exports, "__esModule", {
  value: true
});
Object.defineProperty(exports, "ImageViewer", {
  enumerable: true,
  get: function get() {
    return _ImageViewer.default;
  }
});
Object.defineProperty(exports, "FullScreenViewer", {
  enumerable: true,
  get: function get() {
    return _FullScreen.default;
  }
});
exports.default = void 0;

var _ImageViewer = _interopRequireDefault(_ImageViewer2);

var _FullScreen = _interopRequireDefault(_FullScreen2);

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}

var _default = _ImageViewer.default;
exports.default = _default;
export default exports;
export const __esModule = exports.__esModule,
      ImageViewer = exports.ImageViewer,
      FullScreenViewer = exports.FullScreenViewer;